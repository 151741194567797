.course-unit {
  .new-component-type {
    gap: .75rem;
  }

  .add-component-button {
    @include pgn-box-shadow(1, "down");

    width: 11.63rem;
    height: 6.875rem;
  }
}

.add-component-modal-radio .pgn__form-radio-input {
  min-width: 1.25rem;
}
