.textbooks-empty-placeholder {
  @include pgn-box-shadow(1, "down");

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  border-radius: .375rem;
  padding: map-get($spacers, 4);
}
