.app-list-data-table {
  table {
    table-layout: fixed;
  }

  .pgn__data-table-container {
    tr {
      th {
        background-color: $white;
        text-align: center;

        .d-flex,
        span {
          display: block !important;
        }
      }

      td {
        background-color: $white;

        .pgn__data-table-cell-wrap {
          max-width: unset;
        }
      }
    }
  }
}

.height-36 {
  height: 2.25rem !important;
}

.line-height-20 {
  line-height: 1.25rem !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.py-7px {
  padding: 0 7px;
}

.line-height-24 {
  line-height: 24px !important;
}

.hide-discussion-modal {
  .pgn__modal-header {
    padding-top: 24px;

    h2 {
      color: $primary-500;
      line-height: 28px;
      font-size: 22px;
    }
  }

  .bg-black {
    color: #000000;
  }

  .pgn__modal-footer {
    padding-top: 8px;
    padding-bottom: 24px;
  }

  button {
    font-weight: 500;
  }
}

.discussion-restriction {
  .unselected-button {
    &:hover {
      background: #E9E6E4;
    }
  }

  .action-btn {
    padding: 10px 16px;
    width: 80px;
    height: 44px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }

  .w-92 {
    width: 92px;
  }

  .card-body-section {
    padding-top: 12px !important;
    padding-bottom: 20px !important;
  }

  .form-control {
    border-radius: 0 !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  .collapsible-card {
    padding: 14px 14px 14px 24px !important;
    min-height: 100px;

    .collapsible-trigger {
      padding: 0 !important;

      .badge {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}
