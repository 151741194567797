.course-unit {
  .course-unit__xblocks {
    .course-unit__xblock:not(:first-child) {
      margin-top: 1.75rem;
    }

    .pgn__card-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $light-400;
      padding-bottom: map-get($spacers, 2);

      &:not(:has(.pgn__card-header-subtitle-md)) {
        align-items: center;
      }
    }

    .pgn__card-header-subtitle-md {
      margin-top: 0;
      font-size: $font-size-sm;
    }

    .pgn__card-header-title-md {
      font: 700 1.375rem/1.75rem $font-family-sans-serif;
      color: $black;
    }

    .pgn__card-section {
      padding: map-get($spacers, 3\.5) 0;
    }
  }

  .unit-iframe__wrapper .alert-danger {
    margin-bottom: 0;
  }
}
