.outline-status-bar {
  margin-bottom: .25rem;

  .outline-status-bar__item {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 3.75rem;

    & h5 {
      margin-bottom: 0;
    }
  }
}
