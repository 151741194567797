.configure-modal {
  .configure-modal__header {
    padding-top: 1.5rem;
    position: static;
  }

  .w-7rem {
    width: 7.2rem;
  }

  .mw-1-25rem {
    min-width: 1.25rem;
  }
}
