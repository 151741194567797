.text-black {
  color: $black;
}

.h-200px {
  height: 200px;
}

.mw-300px {
  max-width: 300px;
}
