.assignment-list-item {
  list-style: none;
  display: inline-block;

  &::after {
    content: ",";
  }

  &:last-child {
    &::after { content: ""; }
  }
}

.assignment-list {
  display: inline;
  padding-inline-start: map-get($spacers, 1);
}

//complete checklist item style
.checklist-item-complete {
  box-shadow: -5px 0 0 0 $success-500;
}
