.help-sidebar {
  .help-sidebar-about {
    .help-sidebar-about-title {
      color: $black;
      margin-bottom: 1.25rem;
    }

    .help-sidebar-about-descriptions {
      font: normal $font-weight-normal .875rem/1.5rem $font-family-base;
      color: $text-color-base;
    }

    .help-sidebar-about-link {
      font: normal $font-weight-normal .875rem/1.5rem $font-family-base;
    }
  }

  .help-sidebar-other-links ul {
    list-style: none;
  }

  .help-sidebar-other-title {
    font: normal $font-weight-bold 1.125rem/1.5rem $font-family-base;
    color: $black;
    margin-bottom: 1.25rem;
  }

  .sidebar-link {
    font: normal $font-weight-normal .875rem/1.5rem $font-family-base;
    line-height: 1.5rem;
    color: $info-500;
    margin-bottom: .5rem;
  }
}
