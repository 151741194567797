@import "./empty-placeholder/EmptyPlaceholder";

.configuration-section-name {
  text-transform: lowercase;

  &::first-letter {
    text-transform: capitalize;
  }

  .group-percentage-container {
    width: 1rem;
  }
}

.configuration-card {
  @include pgn-box-shadow(1, "down");

  background: $white;
  border-radius: .375rem;
  padding: map-get($spacers, 4);
  margin-bottom: map-get($spacers, 4);

  .configuration-card-header {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;

    .configuration-card-header__button {
      display: flex;
      align-items: flex-start;
      padding: 0;
      height: auto;
      color: $black;

      &:focus::before {
        display: none;
      }

      .pgn__icon {
        display: inline-block;
        margin-right: map-get($spacers, 1);
        margin-bottom: map-get($spacers, 2\.5);
      }

      .pgn__hstack {
        align-items: baseline;
      }

      &:hover {
        background: transparent;
      }
    }

    .configuration-card-header__title {
      text-align: left;

      h3 {
        margin-bottom: map-get($spacers, 2);
      }
    }

    .configuration-card-header__badge {
      display: flex;
      padding: .125rem map-get($spacers, 2);
      justify-content: center;
      align-items: center;
      border-radius: $border-radius;
      border: .063rem solid $light-300;
      background: $white;

      &:first-child {
        margin-left: map-get($spacers, 2\.5);
      }

      & span:last-child {
        color: $primary-700;
      }
    }

    .configuration-card-header__delete-tooltip {
      pointer-events: all;
    }
  }

  .configuration-card-content {
    margin: 0 map-get($spacers, 2) 0 map-get($spacers, 4);

    .configuration-card-content__experiment-stack {
      display: flex;
      justify-content: space-between;
      padding: map-get($spacers, 2\.5) 0;
      margin: 0;
      color: $primary-500;
      gap: $spacer;

      &:not(:last-child) {
        border-bottom: .063rem solid $light-400;
      }
    }
  }

  .pgn__form-control-decorator-group {
    margin-inline-end: 0;
  }

  .configuration-form-group {
    .pgn__form-label {
      font: normal $font-weight-bold .875rem/1.25rem $font-family-base;
      color: $gray-700;
      margin-bottom: .875rem;
    }

    .pgn__form-control-description,
    .pgn__form-text {
      font: normal $font-weight-normal .75rem/1.25rem $font-family-base;
      color: $gray-500;
      margin-top: .625rem;
    }

    .pgn__form-text-invalid {
      color: $form-feedback-invalid-color;
    }
  }

  .experiment-configuration-form-percentage {
    width: 5rem;
    text-align: center;
  }
}
