.course-grading-assignment-wrapper {
  background-color: $white;
  padding: map-get($spacers, 4);
  text-align: right;
  border: 1px solid $light-700;

  .course-grading-assignment-items {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: .625rem;

    .course-grading-assignment-total-grade {
      grid-area: 2 / 1 / 3 / 2;
    }

    .course-grading-assignment-total-number {
      grid-area: 2 / 2 / 3 / 3;
    }

    .course-grading-assignment-number-droppable {
      grid-area: 2 / 3 / 3 / 4;
    }

    .course-grading-assignment-type-name {
      grid-area: 1 / 1 / 2 / 3;
    }

    .course-grading-assignment-abbreviation {
      grid-area: 1 / 3 / 2 / 4;
    }
  }

  .course-grading-assignment-item-alert-warning {
    .alert-heading {
      font-size: $alert-font-size;
      line-height: $alert-line-height;
    }

    .course-grading-assignment-item-alert-warning-list-label {
      font-size: .75rem;
      line-height: .938rem;
    }

    .course-grading-assignment-item-alert-warning-list {
      font-size: .75rem;
      line-height: .938rem;
      padding-left: 1.875rem;
      margin-bottom: 0;
    }
  }

  .course-grading-assignment-item-alert-success .alert-heading {
    font-size: $alert-font-size;
    line-height: $alert-line-height;
    margin-bottom: 0;
  }
}
