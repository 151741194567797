@import "variables";

.advanced-settings {
  .help-sidebar {
    margin-top: 8.75rem;
  }

  .setting-items-policies {
    .setting-items-deprecated-setting {
      float: right;
      margin-bottom: 1.75rem;
    }

    .instructions,
    strong {
      color: $text-color-base;
      font-weight: 400;
    }
  }

  .setting-card {
    margin-bottom: 1.75rem;

    .pgn__card-header .pgn__card-header-title-md {
      font-size: 1.125rem;
    }
  }
}

.alert-toast {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0 .625rem;
  z-index: $zindex-modal;
}

.alert-proctoring-error {
  list-style: none;
}

.setting-items-list {
  li {
    list-style: none;
  }

  .form-control {
    min-height: 2.75rem;
    flex-grow: 1;
  }

  .pgn__card-header {
    padding: 0 0 0 1.5rem;
  }

  .pgn__card-status {
    padding: .625rem;
  }

  .pgn__card-header-content {
    margin-top: 1.438rem;
    margin-bottom: 1.438rem;
  }
}

.setting-sidebar-supplementary {
  .setting-sidebar-supplementary-about {
    .setting-sidebar-supplementary-about-title {
      font: normal $font-weight-bold 1.125rem/1.5rem $font-family-base;
      color: $headings-color;
      margin-bottom: 1.25rem;
    }

    .setting-sidebar-supplementary-about-descriptions {
      font: normal $font-weight-normal .875rem/1.5rem $font-family-base;
      color: $text-color-base;
    }
  }

  .setting-sidebar-supplementary-other-links ul {
    list-style: none;

    .setting-sidebar-supplementary-other-link {
      font: normal $font-weight-normal .875rem/1.5rem $font-family-base;
      line-height: 1.5rem;
      color: $info-500;
      margin-bottom: .5rem;
    }
  }

  .setting-sidebar-supplementary-other-title {
    font: normal $font-weight-bold 1.125rem/1.5rem $font-family-base;
    color: $headings-color;
    margin-bottom: 1.25rem;
  }
}

.modal-error-item {
  list-style: none;

  .pgn__icon {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    color: $danger;
  }

  .modal-error-item-title {
    display: flex;
    align-items: center;
  }
}

.modal-popup-content {
  max-width: 200px;
  color: $white;
  background-color: $black;
  filter: drop-shadow(0 2px 4px rgba(0 0 0 / .15));
  font-weight: 400;
}

.pgn__modal-popup__arrow::after {
  border-top-color: $black;
}
