.tags-tree {
  .tags-tree-delete-button {
    width: 1rem;
    height: 1rem;

    svg {
      padding: .3rem;
    }

    &:hover {
      svg {
        color: $gray-900;
      }
    }

    &:focus-visible {
      border: 2px solid;
      border-color: $gray-900;
    }
  }
}
