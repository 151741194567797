@import "variables";
@import "../assignment-section/AssignmentSection.scss";
@import "../grading-scale/GradingScale.scss";

.grading {
  .help-sidebar {
    margin-top: 8.75rem;
  }

  .grading-label {
    font: normal $font-weight-bold .75rem/1.25rem $font-family-base;
    color: $gray-500;
  }

  .grading-description {
    font: normal .75rem/1.5rem $font-family-base;
    color: $gray-700;
  }
}
