.section-sub-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .75rem;
  border-bottom: $border-width solid $light-400;

  h2 {
    color: $black;
    margin-bottom: .75rem;
  }
}
