.pgn__sheet-component:has(#content-tags-drawer) {
  min-width: max(500px, 33vw);
}

@media only screen and (max-width: 500px) {
  .pgn__sheet-component:has(#content-tags-drawer) {
    min-width: 100vw;
  }
}

.tags-drawer {
  .tags-drawer-footer {
    right: 0;
    bottom: 0;
  }

  .tags-drawer-cancel-button:hover {
    background-color: transparent;
    color: $gray-300 !important;
  }

  .other-description {
    font-size: .9rem;
  }

  .enable-taxonomies-button:not([disabled]):hover {
    background-color: transparent;
    color: $info-900 !important;
  }
}

// Apply styles to sheet only if it has a child with a .tags-drawer class
.pgn__sheet-component {
  &:has(.tags-drawer) {
    overflow-y: scroll;
    padding: 0;
    min-height: 100vh;
  }
}
