@import "files-and-videos/videos-page/transcript-settings/TranscriptSettings";
@import "files-and-videos/videos-page/VideoThumbnail";
@import "files-and-videos/videos-page/info-sidebar/transcript-item/LanguageSelect";
@import "files-and-videos/videos-page/info-sidebar/transcript-item/TranscriptMenu";
@import "files-and-videos/generic/table-components/GalleryCard";

.files-table {
  #table-filters-dropdown {
    visibility: hidden;
  }

  .pgn__data-table-layout-wrapper {
    overflow-x: visible;
  }

  .pgn__data-table-wrapper {
    background-color: $light-200;
    box-shadow: 0 0 0;

    .pgn__data-table-status-bar {
      padding: 12px 0;

      .pgn__data-table-actions-left {
        margin-top: 16px;
      }
    }

    .pgn__data-table-card-view {
      margin-left: 24px;

      .pgn__card-grid__card-item {
        padding: 0 24px 24px 0;
        margin-bottom: 0;
      }
    }

    .pgn__data-table-footer {
      border-top: none;
      justify-content: center;
    }
  }

  .more-info-menu {
    padding: 8px 0;
    overflow-x: hidden;
    max-height: 500px;
    width: 321px;

    @include pgn-box-shadow(2, "down");

    .pgn__menu-item {
      width: 100%;
      justify-content: start;
      font-weight: 400;

      &:hover {
        background-color: $light-300;
      }
    }
  }
}

.pgn__form-control-set-inline {
  .pgn__form-checkbox {
    margin: 0;
  }

  width: 90%;
  display: inline-grid;
  grid-auto-flow: row;
  gap: 24px 16px;
  grid-template-columns: repeat(3, 33%);
}

.video-upload-spinner {
  width: 1.3rem;
  height: 1.3rem;
}

.video-upload-warning-text {
  font-size: 18px;
}
