.unit-card {
  flex-grow: 1;

  // used in src/course-outline/card-header/TitleLink.jsx &
  // src/course-outline/card-header/TitleButton.jsx as
  // `${namePrefix}-card-title`
  .unit-card-title {
    font-size: $h5-font-size;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
  }
}
