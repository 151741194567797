.sub-header {
  display: flex;
  gap: map-get($spacers, 4\.5);

  .sub-header-actions {
    margin-bottom: 1.75rem;
    align-self: flex-end;
  }
}

.sub-header-title {
  font: normal $font-weight-bold 2rem/2.25rem $font-family-base;
  color: $black;

  .sub-header-title-subtitle,
  .sub-header-breadcrumbs {
    font: normal $font-weight-normal .875rem/1.5rem $font-family-base;
    display: block;
    color: $text-color-base;
  }
}

.sub-header-content-title {
  font: normal $font-weight-normal 1.375rem/1.5 $font-family-base;
  margin-bottom: 0;
  color: $black;
}

.sub-header-instructions {
  font: normal $font-weight-normal .875rem/1.5rem $font-family-base;
  color: $text-color-base;
}

.sub-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 12px;
}
