@import "./course-team-sidebar/CourseTeamSidebar";
@import "./add-user-form/AddUserForm";
@import "./add-team-member/AddTeamMember";
@import "./course-team-member/CourseTeamMember";

.course-team {
  .help-sidebar {
    margin-top: 6.563rem;
  }

  .course-team-section {
    .sidebar-container {
      width: 30%;

      .help-sidebar {
        margin-top: 0;

        hr {
          display: none;
        }
      }
    }

    .members-container {
      flex-grow: 1;
      padding-top: 1.25rem;
    }
  }
}
