.desktop-card {
  width: 19rem;
  height: 14rem;
}

.mobile-card {
  width: 100%;
  height: 14rem;
}

.shadow {
  box-shadow: 0 .125rem .25rem rgb(0 0 0 / .3) !important;
}

[dir="rtl"] {
  .desktop-card,
  .mobile-card {
    .pgn__card-header-actions .pgn__hyperlink .btn-icon {
      transform: scaleX(-1);
    }
  }
}
