.item-card-header {
  display: flex;
  align-items: center;

  .item-card-header__title-btn {
    justify-content: flex-start;
    padding: 0;
    width: fit-content;
    height: 1.5rem;
    margin-right: .25rem;
    background: transparent;
    color: $black;
  }

  .item-card-edit-icon {
    opacity: 0;
    transition: opacity .3s linear;

    &:focus {
      opacity: 1;
    }
  }

  &:hover {
    .item-card-edit-icon {
      opacity: 1;
    }
  }
}
