.subsection-card {
  flex-grow: 1;

  .subsection-card__units {
    margin-top: $spacer;
  }

  .subsection-card-title {
    font-size: $h4-font-size;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
  }

  .subsection-card__content {
    margin-left: 1.7rem;
  }
}
