.add-user-form {
  display: flex;
  flex-direction: column;
  border: .0625rem solid $gray-200;
  border-radius: .375rem;
  box-shadow: 0 1px 1px $gray-200;
  margin-bottom: 1.25rem;
  background-color: $white;

  .form-title {
    font-size: 1.5rem;
    margin-bottom: 1.875rem;
  }

  .form-field {
    padding: 1.25rem 1.875rem;
    margin-bottom: $spacer;

    .pgn__form-group {
      margin-bottom: 0;
    }
  }

  .form-label {
    position: relative;

    &::after {
      margin-left: .3125rem;
      content: "*";
    }
  }

  .form-helper-text {
    font-size: $font-size-xs;
  }

  .pgn__action-row {
    padding: $spacer 1.875rem;
    border-top: .0625rem solid $gray-200;
    justify-content: flex-start;
  }
}
