.embed-video-container {
  @include pgn-box-shadow(1, "down");

  border-radius: .1875rem;

  .introduction-video {
    padding: .75rem;
    height: 23.375rem;
  }
}
