// Simulate Tailwind-style arbitrary value classNames. We have to hard code this one though.
.h-\[calc\(100vh-200px\)\] {
  height: calc(100vh - 200px);
}

// Helper to set a minimum width for the This Course / All Courses toggle
.pgn__menu-select.with-min-toggle-width {
  & > button {
    min-width: 155px;
  }
}

.courseware-search-modal {
  // Fix so the 'This course' / 'All courses' dropdown is not cut off on the right hand side,
  // But still preserve correct scrolling behavior for the results list (vertical)
  // (If we set 'isOverflowVisible: true', the scrolling of the results list is messed up)
  overflow: visible;

  // Highlight matching terms using bold, not yellow highlighting
  mark {
    font-weight: bold;
    background-color: transparent;
    padding: 0;
    display: inline;
  }

  .pgn__modal-header .pgn__menu-select {
    // The "All courses" / "This course" toggle button
    & > button {
      min-width: 155px;  // Set a minumum width so it doesn't change size when you change the selection
      // The current Open edX theme makes the search field square but the button round and it looks bad. We need this
      // hacky override until the theme is fixed to be more consistent.
      border-radius: 0;

      // Needed so the the focus borders matches the button's borders
      &:focus::before {
        border-radius: 0;
      }
    }
  }

  // Options for the "filter by tag" menu
  .pgn__menu.tags-refinement-menu {
    .pgn__menu-item {
      // Make the "filter by tag" menu much wider than normal, because we need the space to display the tags hierarchy
      width: 100%;
    }
  }

  // Options for the "filter by block type" menu
  .pgn__menu.block-type-refinement-menu {
    .pgn__menu-item {
      // Make the "filter by block type" menu expand to fit longer block types names
      width: 100%;
    }
  }

  .pgn__menu-item {
    // Fix a bug in Paragon menu dropdowns: the checkbox currently shrinks if the text is too long.
    // https://github.com/openedx/paragon/pull/3019
    // This can be removed once we upgrade Paragon - https://github.com/openedx/frontend-app-course-authoring/pull/933
    input[type="checkbox"] {
      flex-grow: 0;
      flex-shrink: 0;
    }
    // Fix a bug in Paragon menu dropdowns: very long text is not truncated with an ellipsis
    // https://github.com/openedx/paragon/pull/3019
    // This can be removed once we upgrade Paragon - https://github.com/openedx/frontend-app-course-authoring/pull/933
    > div {
      overflow: hidden;
    }
  }

  .search-result {
    &:hover {
      background-color: $gray-100;
      cursor: pointer;
    }

    &:hover.text-muted {
      cursor: unset;
      background-color: unset;
    }
  }
}
