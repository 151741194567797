.publish-modal {
  max-width: 33.6875rem;

  .pgn__modal-close-container {
    transform: translateY(.5rem);
  }

  .publish-modal__header {
    padding-top: 1.5rem;
  }

  .publish-modal__subsection:not(:last-child) {
    margin-bottom: .5rem;
  }
}
