.course-unit {
  .sub-header {
    background: transparent;
  }

  .sub-header-title .sub-header-breadcrumbs {
    .dropdown-toggle::after {
      display: none;
    }

    [aria-expanded="true"] .pgn__icon {
      transform: scale(1, -1);
    }
  }
}
