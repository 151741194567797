.schedule-section {
  .schedule-date-list {
    display: flex;
    flex-direction: column;
    padding-top: $spacer;
    margin: 0;
    padding-inline-start: 0;
    list-style-type: none;

    .pgn__form-group {
      flex-basis: 48%;
    }
  }

  .schedule-date-item {
    &:not(:last-child) {
      margin-bottom: 1.19rem;
    }
  }

  .schedule-date-item-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 1.5rem;
    border-bottom: .063rem solid rgba(0 0 0 / .1);
    padding-bottom: .625rem;
    margin-bottom: .375rem;
    padding-right: 10%;
  }

  .schedule-date-item-error {
    margin: 0;
    padding: 0;
    color: $form-feedback-invalid-color;
    font: normal $font-weight-normal .75rem/1.25rem $font-family-base;
  }
}
